<template>
	<div class="whiteBack">
    <h4 class="mb-2">Background Check Status</h4>
    <div class="mb-3">
      <vue-good-table
        :columns="columns"
        :rows="userBackgroundChecks"
        styleClass="vgt-table condensed"
        compactMode
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
        }"
      >
        
      </vue-good-table>
    </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['user'],
  data: () => ({
    columns: [
      {
        label: 'Type',
        field: 'type',
      },
      {
        label: 'Status',
        field: 'status',
      },
      {
        label: 'Response',
        field: 'response',
      },
    ]
  }),
  computed: {
    ...mapState(['userBackgroundChecks']),
  },
  beforeDestroy() {
    this.columns = null
    delete this.columns
  }
}
</script>