<template>
	<div class="whiteBack">
    <h4 class="mb-0">Contractor Onboarding</h4>
    <div class="caption mb-3" v-if="user.contractorPayProfile && user.contractorPayProfile.workerId">Everee ID: {{user.contractorPayProfile.workerId}}</div>
    <span class="flex justify-space-between">
    <button class="btn btn__warning btn__small mb-3" v-show="user.contractorPayProfile && user.contractorPayProfile.workerId && (user.contractorPayProfile.onboardingStatus && user.contractorPayProfile.onboardingStatus == 'IN_PROGRESS')">Contractor In Progress
    </button>
    <button class="btn btn__danger btn__small mb-3" v-show="user.contractorPayProfile && user.contractorPayProfile.workerId && (user.contractorPayProfile.onboardingStatus && user.contractorPayProfile.onboardingStatus == 'NOT_STARTED')">Not Started
    </button>
    <button class="btn btn__success btn__small mb-3" v-show="user.contractorPayProfile && user.contractorPayProfile.workerId && user.contractorPayProfile.onboardingComplete">Contractor  <i class="fas fa-check ml-2" style="color:white"></i>
    </button>

    <button class="btn btn__xsmall mb-3" @click="refreshContractor()">Refresh Connection
      <transition name="fade">
          <span class="ml-2" v-if="performingRequest">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
    </button>


    <button class="btn btn__outlined-primary btn__small mt-3" v-show="!user.contractorPayProfile && !user.workerId" @click="onboardContractor()">Onboard Contractor
      <transition name="fade">
          <span class="ml-2" v-if="performingRequest">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
    </button>

    </span>

    <div v-show="user.contractorPayProfile && user.contractorPayProfile.workerId && (user.contractorPayProfile.onboardingStatus && user.contractorPayProfile.onboardingStatus == 'NOT_STARTED')">
    <button class="btn btn__outlined btn__small" @click="onTextPayroll()">
      Send Reminder Text
      <transition name="fade">
        <span class="ml-2" v-if="performingRequest">
        <i class="fa fa-spinner fa-spin"></i>
        </span>
      </transition>
    </button>

    <div class="mb-1 mt-2" v-if="user && user.updatePayrollReminderSent">
       <span class="caption">
        Last Sent: {{user.updatePayrollReminderSent.toDate() | moment("MMMM Do YYYY, h:mm a") }}
        </span>
    </div>
    </div>

    
    <!-- <div class="mt-3">
      <button class="btn btn__outlined-primary btn__small" @click="onboardEmployee()">
        Onboard As Employee
        <transition name="fade">
          <span class="ml-2" v-if="performingRequest">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
      </button>
    </div> -->

	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['user', 'userContractorPayProfile'],
  data: () => ({
    performingRequest: false,
  }),
  methods: {
    refreshContractor() {
      this.performingRequest = true
      let user = this.user
      this.$store.dispatch('refreshContractor', user)
      setTimeout(() => {
        this.performingRequest = false
      }, 500)
    },
    onboardEmployee() {
      this.performingRequest = true
      let user = this.user
      this.$store.dispatch('onboardEmployee', user)
      setTimeout(() => {
        this.performingRequest = false
      }, 500)
    },
    onboardContractor() {
      this.performingRequest = true
      let user = this.user
      this.$store.dispatch('onboardContractor', user)
      setTimeout(() => {
        this.performingRequest = false
      }, 500)
    },
    onTextPayroll () {
      this.performingRequest = true
      let newText = {
        name: this.user.firstName,
        phone: this.user.phone,
        userId: this.user.id,
        type: 'updatePayroll'
      }
      this.$store.dispatch('updatePayrollText', newText)
      setTimeout(() => {
          this.performingRequest = false
      }, 1000)
    }
  },
  beforeDestroy () {
    this.performingRequest = null
    delete this.performingRequest
  }
}
</script>