<template>
	<div>
		<h4 class="mb-1">New User Note</h4>
     <textarea name="note" id="note" cols="30" rows="2" v-model="note"></textarea>
     <button class="btn btn__primary btn__small mt-3" @click="onAddNote()">
     	Add Note
     		<transition name="fade">
          <span class="ml-2" v-if="performingRequest">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
     </button>
  </div>
</template>

<script>
export default {
  props: ['user', 'me'],
  data: () => ({
    performingRequest: false,
    note: '',
  }),
  methods: {
  	async onAddNote() {
  		this.performingRequest = true

      const date = new Date()
      let touch = {
        contactedById: this.me.id,
        contactedByFirstName: this.me.firstName,
        contactedByLastName: this.me.lastName,
        method: "New Note",
        notes: this.note,
        touched: date,
      }
      await this.$store.dispatch("addTouchLog", {
        userId: this.user.id,
        log: touch
      })



      let name = this.me.firstName + ' ' + this.me.lastName
  		let theNote = {
  			note: this.note,
  			userId: this.user.id,
        submittedBy: name
  		}
      console.log(name)
  		this.$store.dispatch('addUserNote', theNote)
  		setTimeout(() => {
  				this.note = ''
          this.performingRequest = false
      }, 1000)
  	}
  },
  beforeDestroy() {
    this.note = null
    delete this.note
    this.performingRequest = null
    delete this.performingRequest
  }
}
</script>