<template>
	<div class="whiteBack" v-bind:class="{ 'invalid': (!user.address || Object.keys(user.address).length == 0) }">
    <span class="flex align-center">
        <h4>Address</h4>
       <button class="btn" v-bind:class="{ 'btn__icon primary': editScreen, 'btn__icon': !editScreen }" @click="edit()"><i class="fa-duotone fa-pen-to-square fa-2x"></i></button>
      </span>

    
      <div v-if="user.address && Object.keys(user.address).length >= 1">
      <div class="caption bold">{{user.firstName}} {{user.lastName}}</div>
        <div class="caption">{{user.address.street_number}} {{user.address.street}} <span v-if="user.address.unit">#{{user.address.unit}}</span><br />{{user.address.city}}, {{user.address.state}} {{user.address.zip}}</div>
      </div>

      <div v-if="editScreen">
        <div class="mb-2 mt-3 caption">Change your address below:</div>
        <label>
          <gmap-autocomplete
            class="v-input v-input__control outline-fix"
             placeholder="Street Address" @place_changed="setPlace">
          </gmap-autocomplete>
        </label>
        <div class="mt-3" v-if="user.address">
          <label for="unit">Unit Number: (optional)</label>
          <input type="text" v-model.trim="user.address.unit" @change="updateProfile()" />
          <!-- <button class="btn btn__primary mt-3" v-if="place">Submit</button> -->
        </div>
      </div>

      <div class="mt-2" v-if="!user.address || Object.keys(user.address).length == 0">
        <button class="btn btn__xsmall btn__outlined mt-2" @click="onTextAddress()">
          Send Reminder Text
          <transition name="fade">
            <span class="ml-2" v-if="performingRequestAddress">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
        </button>
      </div>
      <div class="mb-0 mt-2 caption" v-if="user.updateAddressReminderSent">
          Reminder Sent: {{formatStamp(user.updateAddressReminderSent)}}
      </div>

	</div>
</template>

<style scoped>
  .primary {
    color: #00C897;
  }
  .fa-2x {
    font-size: 2rem;
  }
</style>

<script>
import * as moment from 'moment'

export default {
  props: ['user'],
  data: () => ({
    performingRequestAddress: false,
    performingRequest: false,
    editScreen: false,
    center: { lat: 45.508, lng: -73.587 },
    marker: {},
    address: {},
    place: '',
  }),
  methods: {
    edit() {
      this.editScreen = !this.editScreen
    },
    formatStamp(stamp) {
      if(stamp) {
        const postedDate = new Date(stamp.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YYYY, HH:mm:ss A')
      }
    },
    onTextAddress () {
      this.performingRequestAddress = true
      let newText = {
        name: this.user.firstName,
        phone: this.user.phone,
        userId: this.user.id,
        type: 'addressReminder'
      }
      this.$store.dispatch('updateAddressReminder', newText)
      setTimeout(() => {
          this.performingRequestAddress = false
      }, 1000)
    },
    setPlace(place) {
      this.currentPlace = place
        if (this.currentPlace) {
          const marker = {
            lat: this.currentPlace.geometry.location.lat(),
            lng: this.currentPlace.geometry.location.lng()
          };
          this.marker = {position: marker}
          this.place = this.currentPlace
          this.user.center = marker;
          this.currentPlace = null;
        }
        if (place.address_components) {
          this.performingRequest = true
          this.user.address = {}
          setTimeout(() => {
              this.performingRequest = false
          }, 1000)
          console.log(place)
          console.log(this.user.address)
          var components = place.address_components;
          for (var i = 0, component; component = components[i]; i++) {
          if (component.types[0] == 'street_number') {
              this.user.address.street_number = component['long_name'];
          }
          if (component.types[0] == 'route') {
              this.user.address.street = component['long_name'];
          }
          // if (component.types[0] == 'locality') {
          //     this.user.address.city = component['long_name'];
          // }
          // if (component.types[0] == 'sublocality_level_1') {
          //     this.user.address.city = component['long_name'];
          // }
          // if (component.types[0] == 'natural_feature') {
          //     this.user.address.city = component['long_name'];
          // }
          // if (component.types[0] == 'administrative_area_level_3') {
          //   console.log('administrative_area_level_3')
          //     this.user.address.city = component['long_name'];
          //     console.log(this.user.address.city)
          // }
          if (component.types[0] == 'locality' || component.types[0] == 'sublocality_level_1' || component.types[0] == 'natural_feature' || component.types[0] == 'administrative_area_level_3') {
              this.user.address.city = component['long_name'];
          }
          if (component.types[0] == 'administrative_area_level_1') {
              this.user.address.state = component['short_name'];
          }
          if (component.types[0] == 'country') {
              this.user.address.country = component['short_name'];
          }
          if (component.types[0] == 'postal_code') {
              this.user.address.zip = component['short_name'];
          }
        }
      }

      this.updateProfile()
      this.place = ''
      // this.editScreen = false
    },
    updateProfile(){
      let userProfile = this.user
      this.$store.dispatch('updateTheUser', userProfile)
      // this.showEdit = false
    },
  },
  beforeDestroy() {
    this.editScreen = null
    delete this.editScreen
    this.performingRequest = null
    delete this.performingRequest
  }
}
</script>