<template>
  <div class="dashboard__container">
    <div class="dashboard__container--body" v-if="user">
      <div class="dashboard__container--body--col">

        <div class="whiteBack mb-5">
          <div>
            <h4 class="mb-0">Add Touch</h4>
              <label for="method">Choose Method:</label>
              <v-select
                class="mt-2"
                label="title" 
                :options="contactMethods"
                v-model="newLog"
                >
              </v-select>
              <input type="text" class="mt-4" v-model="newLogNote" placeholder="Note" />
              <button :disabled="!newLog" class="btn btn__small btn__primary mt-4" @click="addLog()">
                Add Log
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
          </div>
        </div>

        <div class="whiteBack mb-5" v-if="user.touchLog && user.touchLog.length >= 1">
          <h4 class="mb-2">Touch Log</h4>
            <vue-good-table
              :columns="columns"
              :rows="sortedTouches"
              styleClass="vgt-table condensed"
              compactMode
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
              }"
            >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'method'">
                <span>{{props.row.method}}</span>
              </span>
              <span v-if="props.column.field == 'notes'">
                <span>{{props.row.notes}}</span>
              </span>

              <span v-if="props.column.field == 'created'">
                <span>{{formatDate(props.row.touched)}}</span>
              </span>
              <span v-if="props.column.field == 'contactedBy'">
                <span>{{props.row.contactedByFirstName}}</span><span> {{props.row.contactedByLastName}}</span>
              </span>

              <span v-if="props.column.field == 'submittedBy'">
                {{props.row.submittedBy}}
                <!-- <router-link :to="`/users/` + props.row.submittedBy" target="_blank">  <i class="fas fa-external-link"></i>
                </router-link> -->
              </span>
            </template>
          </vue-good-table>
          </div>
        

        <div class="whiteBack mb-5">
          <UserNote :user="user" :me="userProfile" />
        </div>
        <div class="whiteBack mb-5">
          <NotesTable :notes="userNotes" />
        </div>
        
        
        
      </div>
      <div class="dashboard__container--body--col">

        <div class="whiteBack mb-5">
          <UserReview :user="user" :me="userProfile" />
        </div>

        <div class="whiteBack mb-5">
          <ProfileContact :user="user" :currentUser="currentUser" />
        </div>
        <div class="mb-5">
          <ProfileAddress :user="user" />
        </div>

        <ProfileEmployeeOnboard :user="user" :userEmployeePayProfile="userEmployeePayProfile" class="mb-5" />

        <ProfileOnboard :user="user" :userContractorPayProfile="userContractorPayProfile" class="mb-5" />

        <div class="mb-5">
          <div class="whiteBack caption">
            <h4 class="mb-2">Accurate Check Details</h4>
            <div class="mb-2">(copy and paste)</div>
            <div class="caption" v-if="user.firstName && user.lastName">{{user.firstName}} {{user.lastName}}</div>
            <div class="mt-2" v-if="user.accurateCheckRef">Accurate Ref: {{user.accurateCheckRef}}</div>
            <div class="mt-2" v-if="user.address">{{user.address.street_number}} {{user.address.street}} <span v-if="user.address.unit">#{{user.address.unit}}</span><br />{{user.address.city}}, {{user.address.state}} {{user.address.zip}}</div>
            <div class="mt-2" v-if="user.dob">DOB: {{user.dob}}</div>
            <div class="mt-2" v-if="user.phone">Phone: {{user.phone}}</div>
            <div class="mt-2" v-if="user.email">Email: {{user.email}}</div>
            <div class="mt-2" v-if="user && user.contractorPayProfile && user.contractorPayProfile.taxpayerIdentifier">
              Last 4: {{user.contractorPayProfile.taxpayerIdentifier.slice(5)}}
            </div>
            <div class="mt-2" v-if="user && user.employeePayProfile && user.employeePayProfile.taxpayerIdentifier">Last 4: {{user.employeePayProfile.taxpayerIdentifier.slice(5)}}
            </div>
          </div>
        </div>

        

        

        <!-- <ProfileEmployeeOnboard :user="user" :employeeFiles="employeeFiles" :userEmployeePayProfile="userEmployeePayProfile" class="mb-5" /> -->

        
        <!-- <div class="whiteBack mb-5" v-if="user.leadApplicationSubmitted">
          <h4>Lead Application</h4>
          
          <label for="appstatus" class="pb-2">Update Status</label>
          <v-select
            label="title" 
            :options="statuses"
            v-model="user.leadStatus"
            @input="updateStatus()"
            :clearable="false"
            >
          </v-select>
          <div class="caption mt-3">
            <div>What areas? {{user.leadApplication.areas}}</div>
            <div>Pass background check? {{user.leadApplication.background}}</div>
            <div>Highest level of education: {{user.leadApplication.education}}</div>
            <div>Comfortable speaking english? {{user.leadApplication.english}}</div>
            <div>Other Languages {{user.leadApplication.languages}}</div>
            <div>Hours per week: {{user.leadApplication.hours}}</div>
            <div>Some Nights and Weekend: {{user.leadApplication.nightsWeekends}}</div>
            <div>Tech Savvy: {{user.leadApplication.tech}}</div>
            <div>Do they have a car: {{user.leadApplication.transportation}}</div>
            <div>Comfortable walking/standing? {{user.leadApplication.walk}}</div>
            <div v-if="user.leadApplication.leadingExperience">Experinence Leading Teams: {{user.leadApplication.leadingExperience}}</div>
            <div v-if="user.leadApplication.clients">Times they've worked directly with clients: {{user.leadApplication.clients}}</div>
            <div v-if="user.leadApplication.anythingElse">Anything else: {{user.leadApplication.anythingElse}}</div>
          </div>
        </div>

        <ProfileBackground :user="user" class="mb-5" /> -->
        <!-- <ProfileSkills :user="user" /> -->
        <!-- <ProfileId :user="user" /> -->
        <!-- <ProfileTags :user="user" :userProfile="userProfile" /> -->
        <!-- <ProfileCerts :user="user" /> -->
        <ProfileBlacklist :user="user" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StarRating from 'vue-star-rating'
import Loader from '@/components/Loader.vue'
import ProfileContact from '@/components/Profile/ProfileContact.vue'
import ProfileAddress from '@/components/Profile/ProfileAddress.vue'
import ProfileEmployeeOnboard from '@/components/Profile/ProfileEmployeeOnboard.vue'
import ProfileOnboard from '@/components/Profile/ProfileOnboard.vue'
import ProfileBackground from '@/components/Profile/ProfileBackground.vue'
import ProfileBlacklist from '@/components/Profile/ProfileBlacklist.vue'
import NotesTable from '@/components/Profile/NotesTable.vue'
import UserNote from '@/components/Profile/UserNote.vue'
import Rewards from '@/components/Rewards.vue'
import UserReview from '@/components/Profile/UserReview.vue'
import router from '@/router'
import * as moment from 'moment'
const fb = require('../../firebaseConfig.js')

export default {
  props: ['user', 'dnr'],
  name: 'userDetails',
  data() {
    return {
    statuses: ['New', 'In Review', 'Hired', 'On-Hold', 'Not Hired', 'Hide/Duplicate'],
    contactMethods: ['Call', 'Text', 'Email'],
    newLog: '',
    newLogNote: '',
    performingRequest: false,
    columns: [
      {
        label: 'Method',
        field: 'method',
        width:'60px',
        sortable: false
      },
      {
        label: 'Notes',
        field: 'notes',
        sortable: false
      },
      {
        label: 'Created',
        field: 'created',
        width:'100px',
        sortable: false
      },
      {
        label: 'Contact By',
        field: 'contactedBy',
        width:'120px',
        sortable: false
      }
    ]
  }},
  
  computed: {
    ...mapState(['currentUser', 'userInfo', 'userProfile', 'userVerifications', 'userContractorPayProfile', 'userEmployeePayProfile', 'userNotes', 'employeeFiles', 'workerFiles']),
    // docHeld() {
    //   if (this.user && this.user.docHold) {
    //     return true
    //   }
    // },
    // dnr() {
    //  if (this.user && this.user.blacklist && this.user.blacklist.length >= 1) {
    //   return true
    //  }
    // }
    // joined() {
    //   if(this.user && this.user.created) {
    //     return new Date(this.user.created.seconds*1000)
    //     console.log(this.currentUser.metaData)
    //   }
    // }
    sortedTouches() {
      let log = Object.values(this.user.touchLog.sort((a, b) => 
        b.touched.toMillis() - a.touched.toMillis()))
      return log

    }
  },
  components: {
    Loader,
    StarRating,
    ProfileContact,
    ProfileAddress,
    ProfileOnboard,
    ProfileEmployeeOnboard,
    ProfileBackground,
    ProfileBlacklist,
    UserNote,
    NotesTable,
    UserReview,
    // UserReview,
    // ReviewsTable,
    
    // ProfileMessage,
    // MessageTable,
    Rewards,
  },
  methods: {
    
    // sortedNotes() {
    //   console.log(this.user.touchLog)
    //   return this.user.touchLog.sort(function(x, y){
    //     if (x.touched.t && y.touched.t) {
    //       let yValue = this.formatStamp(y.touched.t) || null
    //       let xValue = this.formatStamp(x.touched.t) || null
    //       return y - x;
    //     }
    //   })
    // },
    addLog() {
      this.performingRequest = true
      console.log(this.newLog)
      const date = new Date()
      let touch = {
        contactedById: this.userProfile.id,
        contactedByFirstName: this.userProfile.firstName,
        contactedByLastName: this.userProfile.lastName,
        method: this.newLog,
        notes: this.newLogNote || '',
        touched: date,
      }
      this.$store.dispatch("addTouchLog", {
        userId: this.user.id,
        log: touch
      })
      this.newLog = ''
      this.newLogNote = ''
    },
    async updateStatus() {
      await fb.leadApplicationsCollection.doc(this.user.leadApplicationId).update({
        status: this.user.leadApplicationStatus
      })
      this.updateUser()
    },
    updateUser() {
      fb.usersCollection.doc(this.user.id).update(this.user)
    },
    formatStamp(stamp) {
      if(stamp) {
        console.log(stamp)
        const postedDate = new Date(stamp.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YYYY')
      } else {
        return null
      }
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YY')
      } else {
        return null
      }
    },
  },
  beforeDestroy() {
    this.statuses = null
    delete this.statuses
    delete this.updateUser
    delete this.updateStatus
  }
}

</script>