<template>
	<div class="whiteBack">
    <h4 class="mb-0">Everee Payroll Onboarding</h4>
    <div class="caption mb-3" v-if="user.employeePayProfile && user.employeePayProfile.workerId">Everee ID: {{user.employeePayProfile.workerId}}</div>
    <span class="flex justify-space-between">
    <button class="btn btn__warning btn__small mb-3" v-show="user.employeePayProfile && user.employeePayProfile.workerId && (user.employeePayProfile.onboardingStatus && user.employeePayProfile.onboardingStatus == 'IN_PROGRESS')">Employee In Progress
    </button>
    <button class="btn btn__danger btn__small mb-3" v-show="user.employeePayProfile && user.employeePayProfile.workerId && (user.employeePayProfile.onboardingStatus && user.employeePayProfile.onboardingStatus == 'NOT_STARTED')">Not Started
    </button>
    <button class="btn btn__success btn__small mb-3" v-show="user.employeePayProfile && user.employeePayProfile.workerId && user.employeePayProfile.onboardingStatus == 'COMPLETE'">Employee  <i class="fas fa-check ml-2" style="color:white"></i>
    </button>
    </span>

    <div class="mt-3 caption" v-if="!completed">
      Profile is missing data needed to onboard.
    </div>
    <div class="mt-3 flex justify-space-between" v-if="(!user.employeePayProfile || !user.employeePayProfile.workerId)">
      <button :disabled="!completed" class="btn btn__outlined-primary btn__small" @click="onboardEmployee()">
        Onboard As Employee
        <transition name="fade">
          <span class="ml-2" v-if="performingRequest">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
      </button>
      <button class="btn btn__xsmall mb-3 mt-3" @click="refreshEmployee()">Refresh Connection
      <transition name="fade">
          <span class="ml-2" v-if="performingRequest">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
    </button>
    </div>


    <button class="btn btn__outlined btn__small" @click="onTextPayroll()" v-if="(user.employeePayProfile && user.employeePayProfile.workerId && user.employeePayProfile.onboardingStatus != 'COMPLETE')">
      Text Reminder
      <transition name="fade">
        <span class="ml-2" v-if="performingRequest2">
        <i class="fa fa-spinner fa-spin"></i>
        </span>
      </transition>
    </button>
    <span v-if="(userEmployeePayProfile && userEmployeePayProfile.workerId && userEmployeePayProfile.onboardingStatus != 'COMPLETE') && user.updateEmployeePayrollReminderSent" class="caption">
      Last Sent: {{user.updateEmployeePayrollReminderSent.toDate() | moment("MMMM Do YYYY, h:mm a") }}
      </span>

    <div class="mt-2" v-if="(userEmployeePayProfile && userEmployeePayProfile.workerId)">
      <h5 class="mb-1">Employee Details</h5>
      <div class="caption mb-1" v-if="userEmployeePayProfile.hireDate">Hire Date: {{userEmployeePayProfile.hireDate | moment("MM/DD/YYYY")}}</div>
      <div class="caption mb-1" v-if="userEmployeePayProfile.terminationDate">Termination Date: {{userEmployeePayProfile.terminationDate | moment("MM/DD/YYYY")}}</div>
      <div class="caption mb-1" v-if="userEmployeePayProfile.tinVerificationState">TIN Verification: {{userEmployeePayProfile.tinVerificationState}}</div>
      
      <div class="caption mb-1" v-if="userEmployeePayProfile.ptoBalance && userEmployeePayProfile.ptoBalance.availableHours">PTO Balance: {{userEmployeePayProfile.ptoBalance.availableHours}}</div>
    </div>
    <div class="mt-0 caption" v-if="user.hireDate" style="color: #21bcf3;">HD: {{user.hireDate | moment("MM/DD/YYYY")}}</div>
    <div class="mt-0 caption" v-if="user.terminationDate" style="color:#ef4b46;">TD: {{user.terminationDate | moment("MM/DD/YYYY")}}</div>
    <div class="mt-0 caption" v-if="user.employeePayProfile && user.employeePayProfile.bankAccounts && user.employeePayProfile.bankAccounts[0]">Bank Accounts: <span v-for="(item, index) in user.employeePayProfile.bankAccounts" key="index">{{item.bankName}} / {{item.accountNumberLast4}}</span></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['user', 'userEmployeePayProfile', 'employeeFiles'],
  data: () => ({
    performingRequest: false,
    performingRequest2: false,
    columns: [
      {
        label: 'Type',
        field: 'documentType',
        sortable: false,
        width:'120px'
      },
      {
        label: 'Name',
        field: 'fileName',
        sortable: false
      },
      {
        label: 'Link',
        field: 'downloadUrl',
        sortable: false,
        width:'60px'
      }
    ]
  }),
  computed: {
    completed() {
      if (this.user.phone && this.user.firstName && this.user.lastName && this.user.email && this.user.id && this.user.address && this.user.address.city && this.user.address.state && this.user.address.street_number && this.user.address.street && this.user.address.zip) {
        return true
      } else {
        return false
      }
    },
    // inProcess() {
    //   if (user.employeeId && (user.lifecycleStatus == 'ONBOARDING')) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // isEmploee() {
    //   if (user.employeeId && (user.lifecycleStatus == 'COMPLETE')) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  },
  methods: {
    onTextPayroll () {
      this.performingRequest2 = true
      let newText = {
        name: this.user.firstName,
        phone: this.user.phone,
        userId: this.user.id,
        type: 'updateEmployeePayroll'
      }
      this.$store.dispatch('updateEmployeePayrollText', newText)
      setTimeout(() => {
          this.performingRequest2 = false
      }, 1000)
    },
    refreshEmployee() {
      this.performingRequest = true
      let user = this.user
      this.$store.dispatch('refreshEmployee', user)
      setTimeout(() => {
        this.performingRequest = false
      }, 500)
    },
    onboardEmployee() {
      this.performingRequest = true
      let user = this.user
      this.$store.dispatch('onboardEmployee', user)
      setTimeout(() => {
        this.performingRequest = false
      }, 500)
    },
  },
  beforeDestroy() {
    this.columns = null
    delete this.columns
    this.performingRequest1 = null
    delete this.performingRequest1
    this.performingRequest2 = null
    delete this.performingRequest2
  }
}
</script>